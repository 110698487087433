.e-calendar {
  .active-category__hidden {
    display: none !important;
  }

  .active-date__hidden {
    display: none !important;
  }

  .active-view__hidden {
    display: none !important;
  }

  .calendar-hidden {
    display: none !important;
  }

  .active-view {
    background-color: #f2f2f2;
  }

  .calendar-price,
  .price-includes {
    margin-bottom: var(--helper-15);
    width: 50%;
  }

  .calendar-days,
  .weekdays-name {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // flex-wrap: wrap;
    // justify-content: space-between;
    // padding-inline: 18px;
  }

  .padding-day,
  .weekdays-name {
    background: #f5f5f5;
  }

  .padding-day,
  .month-day,
  .current-day,
  .days-name {
    justify-self: center;
    text-align: center;
    width: 100%;
    border: 0.5px solid;
    border-color: rgb(220, 220, 220, 0.25);
  }

  .month-content {
    min-height: 100px;
  }

  .tesvolt-color {
    color: rgb(191, 124, 76);
  }

  .batterx-color {
    color: rgb(100, 112, 128);
  }

  .product-color {
    color: rgb(157, 183, 104);
  }

  .fenecon-color {
    color: rgb(106, 175, 182);
  }

  .padding-day {
    color: #a5a5a5;
  }

  .event-headline {
    margin: 2rem auto;
  }

  .event-course {
    margin-top: 2rem;
  }

  .seperator-2 {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .seperator-3 {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .course-form {
    margin-top: var(--helper-50);
    display: flex;
    flex-direction: column;
  }

  .input-wrapper {
    margin: 20px 0;

    button {
      width: 40%;
    }

    @media (max-width: 43rem) {
      button {
        width: 100%;
      }
    }
  }

  label {
    display: block;
    margin-bottom: var(--helper-15);
  }

  .signin {
    select {
      padding: 10px 5px;
      width: 100%;
    }

    textarea {
      resize: none;
      padding: 5px;
      width: 100%;
      font-size: 0.8rem;
    }
  }

  .honeypot {
    position: absolute;
    left: -9999px;
  }

  .calendar-confirm__wrapper {
    span {
      display: table-cell;
      vertical-align: middle;
    }

    .calendar-confirm {
      width: auto !important;
      margin-right: 10px;
    }

    .calendar-confirm__label {
      display: table-cell;
    }
  }

  strong {
    font-size: 1rem;
    font-weight: 700;
  }

  .calendar-categories {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 10px 15px;

    @media (max-width: 62rem) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .calendar-categories__headline {
      font-weight: 700;
      font-size: 0.9rem;
    }

    .calendar-category {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      border-radius: 6px;
    }

    .active-category {
      background-color: #f2f2f2;
      .calendar-categories__text {
        color: black;
      }
    }

    .calendar-categories__circle {
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      margin-right: 7px;
    }

    .calendar-categories__text {
      color: gray;
      font-weight: 500;
      position: relative;

      // &::before {
      //   position: absolute;
      //   left: -25px;
      //   content: "";
      //   display: inline-block;
      //   width: 15px;
      //   height: 15px;
      //   border-radius: 7.5px;
      //   background-color: red;
      // }
    }
  }

  .event-toolbar {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 62rem) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .event-show__all {
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 5px;
    background: #ccc;
    margin-left: 20px;
    font-weight: 700;
    font-size: 0.9rem;

    @media (max-width: 62rem) {
      margin: 0.8rem 0;
    }
  }

  .event-date__picker {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-weight: 700;
    font-size: 0.9rem;
  }

  .event-month {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-weight: 700;
    font-size: 0.9rem;
    min-width: 130px;
    text-align: center;
  }

  .event-month__picker {
    display: flex;
    align-items: center;

    button {
      background-color: white;
      font-size: 0.9rem;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 5px;
    }
  }

  .event-view {
    display: flex;
    align-items: center;

    .calendar-view__item {
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 6px;
      margin-right: 10px;
      cursor: pointer;
    }

    p {
      font-weight: 700;
      font-size: 0.9rem;
      margin-right: 40px;
      margin-left: 7px;

      @media (max-width: 43rem) {
        margin: 0;
      }
    }

    .icon-calendar {
      width: 30px;
      height: 30px;
      margin: 0.6rem;
    }

    .icon-print {
      margin-left: 100px;
    }
  }

  .event-container {
    position: relative;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin: 10px 0px;
    background: linear-gradient(0deg, white 0%, white 100%);

    .event-header {
      display: flex;
      padding: 0;

      .event-date_container {
        background-repeat: no-repeat;
        border-radius: 4px;
        padding: 0.5rem 0.5rem;
        overflow: hidden;
        text-align: center;
        color: white;
        min-width: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .event-date_mday {
          font-size: 2rem;
        }
      }

      .event-overview {
        margin: 1rem 20px 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .event-title {
          color: #565656;
          font-size: 1.3rem;
          font-weight: 700;

          @media (max-width: 43rem) {
            font-size: 1rem;
          }
        }

        .event-time {
          font-weight: 700;
          font-size: 0.8rem;
          margin-top: 0.5rem;

          @media (max-width: 43rem) {
            font-size: 0.7rem;
          }
        }

        .event-status {
          margin-top: 1rem;
          font-size: 0.8rem;
          display: flex;
          gap: 8px;

          @media (max-width: 43rem) {
            font-size: 0.7rem;
          }

          span {
            padding: 5px;
            border-radius: 5px;
          }

          .fully-booked-event {
            border: 1px solid red;
            color: red;
          }

          .new-event {
            color: white;
            background-color: #565656;
            font-weight: bold;
            border: 1px solid #565656;

            &.batterx, &.products {
              background-color: rgb(157, 183, 104);
              border: 1px solid rgb(157, 183, 104);
            }

            &.fenecon, &.feneconhessen {
              background-color: rgb(106, 175, 182);
              border: 1px solid rgb(106, 175, 182);
            }

            &.tesvolt1 , &.tesvolt2 {
              background-color: rgb(191, 124, 76);
              border: 1px solid rgb(191, 124, 76);
            }
          }
        }
      }
    }

    .event-content__seperator {
      margin-right: 20px;
      margin-left: 105px;
    }

    .event-toogle {
      position: absolute;
      right: 20px;
      top: 30px;
    }

    .event-content {
      margin-left: 105px;
      margin-top: 40px;
      margin-bottom: 40px;
      list-style-position: outside;

      @media (max-width: 43rem) {
        margin-left: 1.3rem;
      }

      .standard-container {
        margin: 0;
      }

      .text-box {
        p {
          margin-top: var(--helper-25) !important;
        }
      }

      ul {
        margin-top: 0;
      }

      .event-content__headerbig {
        color: #8ec100;
        font-weight: 600;
        font-size: 1.3rem;
      }

      .event-content__text {
        margin: 20px 0;
        line-height: 2rem;
        margin-right: 32%;
        color: #565656;
      }

      .event-content__headersmall {
        color: #8ec100;
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
}
