.interactive-svg-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .interactive-svg-description-close {
    display: flex;
    justify-content: flex-end;
    
    button {
        -webkit-appearance: button;
        appearance: button;
        background-color: transparent;
        border: none;
    }
  }
  
  .interactive-svg-headline {
    flex-basis: 100%;
    margin-bottom: -1px;
  }
  
  .interactive-svg-wrapper {
    flex-basis: 100%;
    display: flex;
  }
  
  .interactive-svg {
    flex-basis: 1200px;
    flex-shrink: 1;
  }
  
  .pointers {
    opacity: 0;
  }
  
  .animated #pointers g {
    animation: 2s fadeInPointer backwards;
    animation-delay: calc(var(--order) * 5000ms);
  }
  
  @keyframes fadeInPointer {
    0% {
        opacity: 0;
        transform: translateY(40px);
    }
    2% {
        opacity: 1;
        transform: translateY(40px);
    }
    80% {
        opacity: 1;
        transform: translateY(0px);
    }
  }
  
  .interactive-svg-description {
    display: none;
    position: absolute;
    z-index: 11;
    background: white;
    padding: 1rem;
    top: 0;
    left: 0;
    box-shadow: 2px 4px 7px var(--gray);
    width: 50vw;
    min-width: 200px;
    max-width: 550px;
  
    @media (max-width: 43rem) {
        left: 50% !important;
        transform: translateX(-50%);
        width: 75vw;
    }
  }
  
  .interactive-svg-description.visible {
    display: block;
    animation: fadeInDescription 0.6s forwards ease-out;
  }
  
  @keyframes fadeInDescription {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
  }
  
  .interactive-svg-title {
    font-size: 20px;
  }
  
  .interactive-svg-subline {
    line-height: normal;
  }
  
  .interactive-svg-description-text {
    margin-top: 1.5rem;
  }
  
  .interactive-svg-link {
    font-weight: 900;
    color: var(--primary);
    margin-top: 6px;
    display: block;
  }
  
  .interactive-svg-description-head {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .interactive-svg-description-head img {
    height: 48px;
    flex-basis: 48px;
  }
  
  #turbine, #turbine_2 {
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    transform-origin: center;
    transform-box: fill-box;
  }
  
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }

  .main-stop {
    stop-color: #1E75C3;
  }
  .alt-stop {
    stop-color: #C40638;
  }

.animated {
  .main-stop {
    stop-color: #1E75C3;
    animation: 8s coldChange1 infinite;
  }
  .alt-stop {
    stop-color: #1E75C3;
    animation: 8s coldChange2 infinite;
  }
}

@keyframes coldChange1 {
  0% {
    stop-color: #1E75C3;
  }
  25% {
    stop-color: #1E75C3;
  }
  50% {
    stop-color: #C40638;
  }
  85% {
    stop-color: #1E75C3;
  }
  100% {
    stop-color: #1E75C3;
  }
}

@keyframes coldChange2 {
  0% {
    stop-color: #1E75C3;
  }
  50% {
    stop-color: #C40638;
  }
  85% {
    stop-color: #C40638;
  }
  100% {
    stop-color: #1E75C3;
  }
}

.heat-arrow {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
}

.heat-triangle {
  opacity: 0;
}

.animated .heat-arrow {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: animateHeatArrow 4s linear infinite;
  animation-delay: calc(var(--order) * 1500ms);
}

.animated .heat-triangle {
  opacity: 0;
  animation: animateHeatTriangle 4s linear infinite;
  animation-delay: calc(var(--order) * 1500ms);
}

@keyframes animateHeatArrow {
  0% {
    stroke-dashoffset: 1;
  }
  30%, 70% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 1;
  }
}

@keyframes animateHeatTriangle {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  30%, 70% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.heated-water {
  stroke-dasharray: 1;
  stroke-dashoffset: 20;
}

.animated .heated-water {
  stroke-dasharray: 1;
  stroke-dashoffset: 20;
  animation: animateHeatedWater 4s linear infinite;
}

@keyframes animateHeatedWater {
  from {
    stroke-dashoffset: 20;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.heated-water-backwards {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
}

.animated .heated-water-backwards {
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  animation: animateHeatedWaterBackwards 4s linear infinite;
}

@keyframes animateHeatedWaterBackwards {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 20;
  }
}

#car-to-animate {
  transform: translateX(-35%);
}

.animated #car-to-animate {
  animation: animateCar 10s infinite ease-in-out;
}

@keyframes animateCar {
  0% {
    transform: translateX(-35%);
  }
  20%, 80% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-35%);
  }
}

.animated {
  #wheel1, #wheel2 {
      animation: 10s animateWheels infinite linear;
      transform-origin: center;
      transform-box: fill-box;
  }
}

@keyframes animateWheels {
  0% {
    transform:rotate(0deg);
  }
  20%, 80% {
    transform: rotate(720deg);
  }
  100% {
    transform:rotate(0deg);
  }
}

.animated #car-charger-stroke {
  animation: animateCarCharger 10s infinite;
}

#car-charger-stroke {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
}

@keyframes animateCarCharger {
  0%, 10% {
    stroke-dashoffset: 1;
    opacity: 0;
  }
  15% {
    stroke-dashoffset: 1;
    opacity: 0;
  }
  20%, 75% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  90%, 100% {
    stroke-dashoffset: 1;
    opacity: 0;
  }
}