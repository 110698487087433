@charset "utf-8";

:root {
  .bachner {
    --darkgray: #222;
    --white: #fff;
    --black: #000;

    --primary: #db0021;
    --secondary: #ebebeb;

    --transition-duration: 0.25s;

    --red-jobs: #db0021; //var(--primary);
    --red-references: #db0021; //var(--primary);

    --gray: #999999;
    --lightgray: #cdcdcd;

    --highlight-gray: #54646b;
    --footer-gray: #54646b; //var(--highlight-gray);
    --jobs-text-color: #fff;
  }
}

:root {
  .energiefabrik {
    --darkgray: #999999;
    --white: #fff;
    --black: #000;

    --primary: #95c11f;
    --secondary: #999999;

    --transition-duration: 0.25s;

    --red-jobs: #95c11f; //var(--primary);
    --red-references: #95c11f; //var(--primary);

    --gray: #999999;
    --lightgray: #cdcdcd;

    --highlight-gray: #999999;
    --footer-gray: #999999; //var(--highlight-gray);
    --jobs-text-color: #fff;
  }
}

:root {
  .volthaus {
    --darkgray: #999999;
    --white: #fff;
    --black: #000;

    --primary: #ff9900;
    --secondary: #999999;

    --transition-duration: 0.25s;

    --red-jobs: #ff9900; //var(--primary);
    --red-references: #ff9900; //var(--primary);

    --gray: #999999;
    --lightgray: #b3b3b3;

    --highlight-gray: #999999;
    --footer-gray: #999999; //var(--highlight-gray);
    --jobs-text-color: #000;
  }
}

:root {
  .senertec {
    --darkgray: #999999;
    --white: #fff;
    --black: #000;

    --primary: #006a4d;
    --secondary: #999999;

    --transition-duration: 0.25s;

    --red-jobs: #006451; //var(--primary);
    --red-references: #006451; //var(--primary);

    --gray: #999999;
    --lightgray: #b3b3b3;

    --highlight-gray: #999999;
    --footer-gray: #999999; //var(--highlight-gray);
    --jobs-text-color: #fff;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  word-break: break-word;
}

body {
  background-color: var(--white);
  color: var(--black);
}

:root {
  --font-size-h1: 1.5rem;
  --font-size-h2: 1.4rem;
  --font-size-h3: 1.3rem;
  --font-size-h4: 1.2rem;
  --font-size-h5: 1.1rem;
  --font-size-h6: 1.05rem;
  --font-size-p: 1rem;

  --helper-15: 0.25rem;
  --helper-25: 0.5rem;
  --helper-50: 1rem;
  --helper-75: 1.5rem;
  --helper-100: 2rem;
  --helper-150: 2.5rem;

  --helper-32: 0.75rem;
}

@media (min-width: 43rem) {
  :root {
    --font-size-h1: 2.9rem;
    --font-size-h2: 2.2rem;
    --font-size-h3: 1.7rem;
    --font-size-h4: 1.3rem;
    --font-size-h5: 1.2rem;
    --font-size-h6: 1.1rem;
    --font-size-p: 1rem;

    --helper-15: 0.475rem;
    --helper-25: 0.85rem;
    --helper-50: 1.7rem;
    --helper-75: 2.9rem;
    --helper-100: 3.05rem;
    --helper-150: 4.75rem;

    --helper-32: 1.16rem;
  }
}

@media (min-width: 62rem) {
  :root {
    --font-size-h1: 3.975rem;
    --font-size-h2: 2.81rem;
    --font-size-h3: 2.02rem;
    --font-size-h4: 1.455rem;
    --font-size-h5: 1.25rem;
    --font-size-h6: 1.125rem;
    --font-size-p: 1rem;

    --helper-15: 0.6625rem;
    --helper-25: 1.1375rem;
    --helper-50: 2.275rem;
    --helper-75: 4.05rem;
    --helper-100: 3.9rem;
    --helper-150: 6.625rem;

    --helper-32: 1.5rem;
  }
}

@media (min-width: 82rem) {
  :root {
    --font-size-h1: 5.625rem;
    --font-size-h2: 3.75rem;
    --font-size-h3: 2.5rem;
    --font-size-h4: 1.625rem;
    --font-size-h5: 1.375rem;
    --font-size-h6: 1.25rem;
    --font-size-p: 1rem;

    --helper-15: 0.9375rem;
    --helper-25: 1.5625rem;
    --helper-50: 3.125rem;
    --helper-75: 4.6875rem;
    --helper-100: 6.25rem;
    --helper-150: 9.375rem;

    --helper-32: 2rem;
  }
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media (prefers-reduced-motion) {
  :root {
    --transition-duration: 0;
  }
}

html {
  scroll-behavior: smooth;
}

/***************************/
/***** BASIC TYPOGRAPHY ****/
/***************************/

@font-face {
  font-family: "Open Sans";
  src: url("../../public/assets/fonts/OpenSans-VariableFont_wght.ttf");
  font-weight: 100 900;
}

@font-face {
  font-family: "Spartan";
  src: url("../../public/assets/fonts/Spartan-VariableFont_wght.ttf");
  font-weight: 125 950;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: Spartan !important;
  font-size: 100%;
}
* {
  font-family: Spartan !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
}

h1 {
  text-transform: uppercase;
}

h1,
h2,
h3 {
  font-weight: 900;
}

h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

p {
  font-size: var(--font-size-p);
  line-height: 1.65;
  font-weight: 400;
}

small {
  font-size: 0.75rem;
}

a {
  color: inherit;
}

.a-has-no-underline {
  text-decoration: none;
}

.is-bold {
  font-weight: 700;
}

/***************************/
/***** BASIC LAYOUT ********/
/***************************/

.heading-slim {
  margin-bottom: 0.25rem;
  padding-top: 0;
}

img {
  max-width: 100%;
}

.standard-container > figure > img {
  margin-top: var(--helper-32);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

section {
  &:not(:first-of-type) {
    margin-top: var(--helper-150);
  }

  &.background-primary,
  &.background-secondary {
    padding-top: var(--helper-50);
    padding-bottom: var(--helper-50);
  }

  &:first-of-type {
    & > div.background-image-2 {
      margin-top: calc(-1 * var(--helper-100));
    }
  }
}

main {
  & > section:first-of-type {
    padding-top: var(--helper-100);
  }
}

.main-wrapper {
  margin-bottom: auto;
}

.standard-container,
.header-container {
  margin: 0 auto;
}
.narrow-container {
  align-items: center;
  margin: 0 auto;
}
.tiny-container {
  max-width: 35rem;
}
.small-container {
  max-width: 53.125rem;
}
.standard-container {
  max-width: 92.5%;
}
.header-container {
  max-width: 90%;
}
@media (min-width: 90rem) {
  .standard-container {
    max-width: 83.75rem;
  }

  .header-container {
    max-width: 71.25rem;
  }
}

.text-box {
  font-size: var(--font-size-p);
  max-width: 53.125rem;

  &:not(:last-child) {
    margin-bottom: var(--helper-50);
  }

  p {
    &:not(:first-child) {
      margin-top: var(--helper-50);
    }
  }
}

.text-box_hero--img {
  font-size: 1rem;
  margin-bottom: 3rem;
  width: 100%;
}

.background-primary {
  background: var(--primary);
  color: var(--white);
}

.background-secondary {
  background: var(--secondary);
  color: var(--black);
}

.background-white {
  background: var(--white);
  color: var(--primary);
}

.background-black {
  background: var(--black);
  color: var(--white);
}

.background-image-1 {
  background-image: url(../../public/assets/img/background_kontakt.jpg);
  background-size: cover;
}

.background-image-2,
.background-image-2-offset {
  background-image: url(../../public/assets/img/background_fancy.png);
  background-origin: padding-box;
  background-position: top 0 right 0;
  background-repeat: no-repeat;
  background-size: calc(9rem + 19.125 * ((100vw - 20rem) / 70));
}
.background-image-2 {
  padding-top: var(--helper-100);
  background-position: top var(--helper-100) right 0;
}
.background-image-2-offset {
  background-position: top 7.8125rem right 0;
}

@media (min-width: 90rem) {
  .background-image-2,
  .background-image-2-offset {
    background-size: 28.125rem;
  }
}

.boxes-primary {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  list-style-type: none;
  row-gap: 4rem;
}

.boxes-primary > * {
  background: var(--primary);
  display: block;
  height: 350px;
}

/***************************/
/***** HELPER **************/
/***************************/

.v-padding-less {
  padding-left: 0;
  padding-right: 0;
}

.top-padding-less {
  padding-top: 0 !important;
}

.position-unset {
  position: unset;
}

.has-margin-top-0 {
  margin-top: 0 !important;
}

.has-margin-top-25 {
  margin-top: var(--helper-25) !important;
}

.has-margin-top-50 {
  margin-top: var(--helper-50) !important;
}
.has-margin-bottom-50 {
  margin-bottom: var(--helper-50) !important;
}

.has-margin-top-75 {
  margin-top: var(--helper-75) !important;
}

.has-margin-top-100 {
  margin-top: var(--helper-100) !important;
}

.has-margin-top-150 {
  margin-top: var(--helper-150) !important;
}

.has-margin-bottom-150-negative {
  margin-bottom: calc(-1 * var(--helper-150));
}

.has-padding-top-25 {
  padding-top: var(--helper-25);
}
.has-padding-v-50,
.has-padding-top-50 {
  padding-top: var(--helper-50) !important;
}
.has-padding-top-75 {
  padding-top: var(--helper-75);
}
.has-padding-top-100 {
  padding-top: var(--helper-100);
}
.has-padding-bottom-25 {
  padding-bottom: var(--helper-25);
}
.has-padding-v-50,
.has-padding-bottom-50 {
  padding-bottom: var(--helper-50) !important;
}

.has-text-white {
  color: var(--white);
}

.has-text-primary {
  color: var(--primary);
}

.has-background-primary {
  background-color: var(--primary);
}

.has-background-secondary {
  background-color: var(--highlight-gray);
  color: var(--white);
}

.is-uppercase {
  text-transform: uppercase;
}

@media (max-width: 62rem) {
  .hidden-mobile {
    display: none !important;
  }
}

button:focus {
  outline: none;
}

a,
button,
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

button {
  cursor: pointer;
}

.has-text-centered {
  text-align: center !important;
}

@media (max-width: 43rem) {
  .background-position-small-top {
    background-position: top;
  }

  .background-position-small-bottom {
    background-position: bottom;
  }

  .background-position-small-left {
    background-position: left;
  }

  .background-position-small-right {
    background-position: right;
  }

  .background-position-small-center {
    background-position: center;
  }
}

@media (min-width: 90rem) {
  .background-position-large-top {
    background-position: top;
  }

  .background-position-large-bottom {
    background-position: bottom;
  }

  .background-position-large-left {
    background-position: left;
  }

  .background-position-large-right {
    background-position: right;
  }

  .background-position-large-center {
    background-position: center;
  }
}

.background-position-top {
  background-position: top;
}

.background-position-bottom {
  background-position: bottom;
}

.background-position-left {
  background-position: left;
}

.background-position-right {
  background-position: right;
}

.background-position-center {
  background-position: center;
}

.object-position-top {
  object-position: top;
}

.object-position-bottom {
  object-position: bottom;
}

.object-position-left {
  object-position: left;
}

.object-position-right {
  object-position: right;
}

.object-position-center {
  object-position: center;
}
