.location-description {
    display: none;
    position: absolute;
    z-index: 11;
    background: white;
    padding: 1rem;
    top: 0;
    left: 0;
    box-shadow: 2px 4px 7px var(--gray);

    @media (max-width: 43rem) {
        left: 50% !important;
        transform: translateX(-50%);
    }
}

.location-description.visible {
    display: block;
}

.location-description-address {
    margin: 0;
}

.location-map-image {
    width: 250px;
    height: 150px;
    object-fit: cover;
}

.location-map-logo {
    height: 100px;
    width: 175px;
    object-fit: cover;
}

.standard-container.location-map-container {
    position: unset;
}

.location-map-section {
    position: unset;
}

.location-description-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
}

.location-description-link {
    font-weight: 900;
    color: var(--primary);
    margin-top: 6px;
    display: block;
}

.location-map-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap-reverse;
}

.location-map-large {
    flex-basis: 75%;

    @media (max-width: 43rem) {
        flex-basis: 100%;
    }
}

.location-map-small-wrapper {
    flex-basis: 25%;

    @media (max-width: 43rem) {
        flex-basis: 100%;
    }
}

@media (max-width: 43rem) {
    .location-map-small {
        width: 75%;
        margin: 0 auto;
    }
}

.location-map-de-at {
    width: 100%;
}