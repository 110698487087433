/**
 * Footer
 *
 * Contains all styling for the footer of the site.
 */

/***************************/
/***** FOOTER **************/
/***************************/

.site-footer {
  background: var(--footer-gray);
  /*padding: 4rem 0 ;*/
  padding-top: 6.25rem;
  padding-bottom: 9.375rem;

  ul li {
    float: left;
    width: 50%;
    margin: 1rem 0;

    @media (max-width: 35.12rem) {
      float: unset;
      width: 100%;
      margin: 1.5rem 0;
    }
  }
}

.site-footer__container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (max-width: 62rem) {
    flex-wrap: wrap;
  }
}

.site-footer__additional-links {
  @media (max-width: 62rem) {
    flex-basis: 100%;
    margin-top: 2rem;
  }
}

.site-footer__red_bar {
  width: 20rem;
  background-color: var(--primary);
  height: 2rem;
  position: relative;
  top: -6rem;
}
@media (min-width: 35.12rem) {
  .site-footer__red_bar {
    top: -9.148rem;
  }
}

.site-footer__nav-list {
  list-style-type: none;
  padding-left: 0;
}

.site-footer__link {
  color: #fff;
  display: inline-block;
  font-size: var(--font-size-p);
  font-weight: 400;
  /*padding: 0.5rem 0;*/
  text-decoration: none;
}

.site-footer__right {
  align-self: flex-end;
  flex: 1;
  /*margin-bottom: 1rem;*/
}

.site-footer__right .icons .icons__placeholder {
  height: 3rem;
}

/***************************/
/***** SEARCH **************/
/***************************/

.search-form {
  display: flex;
  justify-content: flex-end;


  @media (max-width: 62rem) {
    justify-content: flex-start;
  }
}

.search-form__input {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin-bottom: 1rem;
  padding: 0.25rem;
}

.search-form__input::placeholder {
  color: #eee;
}
