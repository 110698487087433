/**
 * Header
 *
 * Contains all styling for the header of the site.
 */

/***************************/
/***** SITE HEADER *********/
/***************************/

.site-header {
  position: sticky;
  right: 0;
  left: 0;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 10;
}

.top {
  top: -195px;
}

.site-header__container {
  display: flex;
  justify-content: space-between;
  padding: var(--helper-25) 0;
  max-width: 92.5%;
  margin: 0 auto;
}

@media (min-width: 90rem) {
  .site-header__container {
    max-width: 83.75rem;
  }
}

.site-header__logo {
  img {
    width: auto;
    height: 8.75rem;
  }
}

@media (max-width: 62rem) {
  .site-header {
    position: sticky;
    top: 0;
    width: 100%;
  }
  .site-header__logo {
    img {
      height: calc(2 * var(--helper-100));
    }
  }
  .site-header__right {
    margin: auto 0;
    z-index: 1;
  }

  //body {
  //  margin-top: calc(2 * var(--helper-25) + 4.375rem);
  //}
  //
  *[id] {
    scroll-margin-top: calc(2 * var(--helper-25) + 2 * var(--helper-100) + 6px);
  }
}
