@import "../../../node_modules/swiper/swiper";

html {
  --slider-stroke-width: 1;
}

section,
.header-container,
.standard-container,
.swiper-container {
  position: relative;
}

.swiper-container {
  overflow: hidden;
}

.swiper-slide {
  height: auto;
}

/***************************/
/***** SLIDER BUTTONS ******/
/***************************/

.slider-prev,
.slider-next {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 4rem;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -2rem);
  width: var(--helper-50);
  z-index: 1;
  margin: 0 !important;

  svg {
    stroke: var(--black);
  }
}

.background-primary {
  .slider-next,
  .slider-prev {
    svg path{
      stroke: var(--white);
    }
  }
}

.slider-prev svg,
.slider-next svg {
  height: auto;
  width: 100%;
  stroke-width: var(--slider-stroke-width);
}

.slider-prev {
  left: 0;
}

.slider-next {
  right: 0;
}

.slick-arrow {
  stroke-width: 1;
}

@media (min-width: 60rem) {
  .slider-prev svg,
  .slider-next svg {
    stroke-width: var(--slider-stroke-width);
  }
}

.header-container {
  .slider-next {
    right: calc(-1 * var(--helper-50));
  }

  .slider-prev {
    left: calc(-1 * var(--helper-50));
  }
}

@media (min-width: 60rem) {
  .header-container {
    .slider-next {
      right: -5%;
    }

    .slider-prev {
      left: -5%;
    }
  }
}

@media (min-width: 90rem) {
  .header-container {
    .slider-next {
      right: calc((71.25rem - 83.75rem) / 2);
    }

    .slider-prev {
      left: calc((71.25rem - 83.75rem) / 2);
    }
  }
}

.swiper-button-lock,
.swiper-button-disabled {
  opacity: 0.4;
  cursor: default;
}
.swiper-button-hidden {
  display: none;
}

.swiper-button-disabled {
  display: none;
}

/***************************/
/***** SLIDER PROGRESS ******/
/***************************/

.swiper-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  background-color: var(--primary);
  height: 2px;
  z-index: 1;
  width: 0;
}

.swiper-progress.swiper-progress-active {
  animation: slide-progress 5s cubic-bezier(0.3, 0, 0.3, 1) forwards;
}

@keyframes slide-progress {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
/***************************/
/***** LEISTUNG ************/
/***************************/

.performance {
  padding: 3.125rem 0;
}

.performance__item {
  display: flex !important;
  flex-direction: column !important;
  height: auto;
  padding: 0 0.5rem;
}
@media (min-width: 60rem) {
  .performance__item {
    flex-direction: row !important;
  }
}

.performance__image {
  background: var(--darkgray);
  border: 1px solid #222;
  display: block;
  height: 10rem;
  object-fit: cover;
  object-position: top left;
  position: relative;
  width: 10rem;
}

.performance__info {
  padding: 2rem 0 0 0;
}
@media (min-width: 60rem) {
  .performance__info {
    padding: 0 0 0 2rem;
  }
}

.performance__short-description {
  font-size: 0.975rem;
  line-height: 1.2;
  max-width: 53.125rem;
}

/***************************/
/***** SUMMARY *************/
/***************************/

/* Important!
 * Styles for summary_item are inline,
 *  generated by wysiwyg editor
 */

.summary__item {
  display: flex !important;
  justify-content: center;
  padding: 3rem;
  margin: 0;
  background-position: center;
  background-size: cover;
}

.summary__inner {
  align-items: center;
  background: rgba(224, 35, 9, 0.7);
  display: inline-flex;
  flex-direction: column;
  padding: 6rem;
  margin: 3rem;

  @media (max-width: 43rem) {
    padding: 2rem;
  }
}

.energiefabrik .summary__inner {
  background: rgba(149, 193, 31, 0.9);
}

.smaller-slider .summary__inner {
  max-width: 75%;

  @media (max-width: 43rem) {
    max-width: unset;
  }
}

.tallerimage-slider {
  .summary__item {
    @media (max-width: 43rem) {
      padding: 1rem;
    }
  }

  .summary__inner {
    margin: 6rem 3rem;

    @media (max-width: 43rem) {
      margin: 3rem 0;
    }

    @media (min-width: 82rem) {
      margin: 9rem 3rem;
    }
  }
}
.normaltext-slider .summary__inner p{
  font-weight: normal;
  line-height: 1.65;

  @media (max-width: 43rem) {
    font-size: 14px;
  }
}

.summary__inner p {
  color: white;
  font-weight: bold;
  font-size: 18px;

  @media (max-width: 43rem) {
    font-size: 10px;
    word-break: normal;
  }
}

.summary__inner * {
  margin: 0;
  padding: 0;
  line-height: initial;
}

.summary__inner h1 {
  color: white;
  font-weight: bold;
  font-size: 90px;
}

.summary__inner h2 {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.summary__inner h4 {
  color: white;
}
/***************************/
/***** REFERENCES SLIDER ***/
/***************************/

.references-slider {
  position: relative;
}

.references-slider-button {
  top: 7.5rem; //half of references__image height.
  &.slider-prev {
    transform: translateX(-100%);
  }
  &.slider-next {
    transform: translateX(100%);
  }
}

.references__item.references__item.references__item {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 1px;
  width: 33%;
}

.references__image {
  height: 15rem;
  margin-bottom: var(--helper-15);
  width: 21.5625rem;
  object-fit: cover;
}

.references__info {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 3.125rem;

  & > *:not(:last-child) {
    margin-bottom: var(--helper-15);
  }
}

.references__link {
  align-self: flex-start;
  background: var(--red-references);
  box-shadow: 2px 4px 7px var(--gray);
  color: var(--white);
  display: inline-block;
  font-size: 0.975rem;
  font-weight: 700;
  padding: 0.75rem 1.75rem;
  text-decoration: none;
  margin: auto 0 0.5rem;
  transition: transform 0.2s;
}

.references__link:hover {
  transform: scale(1.1);
}

.background-primary {
  .references__link {
    background: var(--secondary);
    color: var(--black);
    box-shadow: 2px 4px 7px var(--darkgray);
  }
}

@media (min-width: 90rem) {
  .references__link {
    font-size: 1.125rem;
  }
}

.references__link--small {
  font-size: 0.75rem;
}

/***************************/
/** REFERENCES SLIDER HOME**/
/***************************/

.referenceslider__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50% 50%;
  grid-gap: 0;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.referenceslider__item-1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}

.referenceslider__item-2 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

.referenceslider__item-3 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.referenceslider__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.slider-next-round {
  border: none;
  background: none;
  width: 50px;
  position: absolute;
  top: 44%;
  transform: translate(-50%, -50%);
  z-index: 1;
  transition: 0.2s;
  margin-top: 0 !important;
}

.slider-next-round:hover {
  width: 60px;
}

@media (max-width: 43rem) {
  .referenceslider__item-1 {
    display: none;
  }

  .referenceslider__item-2 {
    display: none;
  }

  .referenceslider__item-3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .slider-next-round {
    display: none;
  }
}

.references-section {
  margin-top: 0 !important;
}

/***************************/
/***** TEAM ****************/
/***************************/

.carousel.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.carousel.team .team__inner {
  margin-bottom: var(--helper-50);
}

.carousel.team .team__image {
  align-self: flex-start;
}

@media (max-width: 43rem) {
  .carousel.team .team__inner {
    margin-bottom: var(--helper-100);
  }
}

.team__inner {
  display: flex;
  padding-left: 0.1rem;
  width: 100%;
}

.team__image {
  align-self: center;
  background: transparent;
  border: 1px solid #fff;
  float: left;
  width: 13.75rem;
}

.team__info {
  padding-left: var(--helper-25);
  min-width: 0;
  min-width: 16rem;
  max-width: 25rem;
  flex-grow: 1;

  .team__social-media-links {
    span:not(:last-of-type) {
      &:after {
        content: "\2022";
        margin: 0.2em;
        font-weight: 500;
      }
    }
  }
}

.team__info {
  margin-top: var(--helper-25);
}

.team__mobile-toggle {
  line-height: 1;
  margin-top: 0;
}

.team__info dt,
.team__info dd {
  line-height: 1.6;
  white-space: pre-wrap;
  font-size: var(--font-size-p); //calc(1.5625vw + 0.5625rem);
  word-break: break-word;
}

.team__info dt {
  float: left;
  clear: left;
}

.team__info dl {
  padding-top: var(--helper-25);
  word-break: break-all;
}

.team__info dl dd:first-of-type {
  padding-bottom: var(--helper-25);
}

@media (min-width: 90rem) {
  .team__image {
    height: 17.5rem;
    width: 13.75rem;
  }
}

@media (max-width: 43rem) {
  .team__inner {
    flex-direction: column;
  }
  .team__info {
    padding: var(--helper-50) 0 0 0;
  }
}

.team__info dd::after {
  content: "\A";
}

.team__social-media,
.team__email {
  color: inherit;
  text-decoration: inherit;
}

/***************************/
/***** SERVICE *************/
/***************************/

.service__item {
  display: grid;
  grid-template:
    "img" minmax(min-content, max-content)
    "header" minmax(min-content, max-content)
    "info" minmax(min-content, max-content) / auto;
  gap: var(--helper-25) var(--helper-50);

  .service__caption {
    grid-area: header;
    //margin-top: var(--helper-25);
  }

  .service__image {
    grid-area: img;
    height: 15.625rem;
    width: 23.125rem;
    object-fit: cover;
    object-position: center;
  }

  .service__info {
    grid-area: info;
    height: 100%;

    .service__description {
      margin-bottom: var(--helper-25);
    }

    .service__link {
      color: white;
      background-color: var(--primary);
      text-decoration: none;
      font-size: var(--font-size-p);
      font-weight: 700;
      margin-bottom: 8px;
    }
  }
}

@media (min-width: 77.5rem) {
  .service__item {
    grid-template:
      ". header" minmax(min-content, max-content)
      "img info" auto / 23.125rem auto;
  }

  .service__caption {
    margin-top: 0;
  }
}

/***************************/
/***** TESTIMONIALS ********/
/***************************/

.testimonials-wrapper {
  .slider-next {
    right: calc(-1 * var(--helper-50));
  }
  .slider-prev {
    left: calc(-1 * var(--helper-50));
  }
}

/***************************/
/***** IMAGES **************/
/***************************/

.images-wrapper {
  position: relative;

  .slider-prev {
    transform: translate(-100%, -50%);
  }
  .slider-next {
    transform: translate(100%, -50%);
  }

  .images {
    &.centered {
      figure {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

/***************************/
/*** contentslider Block ***/
/***************************/

.block-contentslider {
  padding-left: 35px;
}


/***************************/
/******* Home Slider *******/
/***************************/

.home-slider-slide {
  background-repeat: no-repeat;
  background-size: cover;
  height: 46.875rem;
}

@media (max-width: 43rem) {
  .home-slider-slide.has-mobile-image {
    background-image: var(--mobile-img-url) !important;
  }
}

.home-slider-item {
  display: flex;
  margin: 0 auto;
  max-width: 92.5%;
  height: 100%;
  align-items: center;

  @media (min-width: 43rem) {
    margin: 0;
  }

  @media (min-width: 90rem) {
    max-width: 83.75rem;
    margin: 0 auto;
  }

  &.align-end-full-width-mobile {
    @media (max-width: 43rem) {
      align-items: flex-end;
      max-width: 100%;
    } 
  }
}

.home-slider-column {
  max-width: 1340px;
  padding: 50px;
  background-color: #00000099;
  color: white;

  @media (min-width: 43rem) {
    width: 50%;
  }

  .pill {
    box-shadow: none;
  }
}
