/**
 * Navigation Main
 *
 * Contains all styling for the main navigation of the site.
 */

.site-header__navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;

  & > ul {
    display: flex;
    flex-wrap: wrap-reverse;
    list-style-type: none;
    padding-left: 0;
    justify-content: flex-end;
    margin-right: -1.2rem;
  }
}

.site-header__navigation-wrapper {
  height: 100%;
}

.site-header__navigation-toggle,
.site-header__navigation-toggle[aria-expanded="true"] .site-header__navigation-toggle--closed,
.site-header__navigation-toggle[aria-expanded="false"] .site-header__navigation-toggle--open {
  display: none
}

.site-header__navigation-toggle[aria-expanded="false"] .site-header__navigation-toggle--closed,
.site-header__navigation-toggle[aria-expanded="true"] .site-header__navigation-toggle--open {
  display: block;
}

@media (max-width: 62rem) {
  .site-header__navigation {
    & > ul {
      display: block;
      margin-right: 0;

      .submenu-mobile {
        margin-left: 2.4rem;
      }
    }

    & > ul,
    & > aside {
      a {
        width: 100%;
      }
    }

    & > .site-toolbar {
      margin: 0 0.7rem;
    }
  }

  .site-header__navigation-toggle {
    background: none;
    border: none;
    display: block;
    height: 3rem;
    margin: 0 auto;
    width: 3rem;
    cursor: pointer;
  }

  .site-header__navigation-toggle svg {
    height: auto;
    width: 100%;
  }
}

/***************************/
/***** SUBMENU *************/
/***************************/

.submenu__wrapper {
  background: #fff;
  display: none;
  filter: drop-shadow(0 5px 7px #aaa);
  padding: 2rem 0;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1;
}

.submenu__wrapper::before {
  background-color: var(--primary);
  content: "";
  height: 0.3rem;
  left: calc(var(--left));
  position: absolute;
  top: 0; /* own height */
  width: calc(var(--width));
}

.submenu__container {
  display: flex;
  max-height: calc(100vh - var(--top) - 4rem); /* 4 rem own padding */
  justify-content: flex-start;
  align-items: unset;
}

.submenu__content {
  align-content: flex-start;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  width: 50%;
  gap: 1rem;
}

.submenu__references-wrapper,
.submenu__item {
  .submenu__references-link,
  .submenu__link {
    color: #000;
    font-size: 1.1875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-left: 0;
    padding: 0.25rem 2rem 0.25rem 0;
    position: relative;
  }
}

.submenu__item {
  font-size: 0.9rem;
  margin: 0.75rem 0;
  min-width: calc(50% - 0.5rem);

  .submenu__link::after {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path stroke='black' fill='none' stroke-width='1.5' d='M3,1L7,5L3,9'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    bottom: 0.25rem;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }
}

@media (max-width: 62rem) {
  .submenu__wrapper {
    position: initial;
    background: initial;
    filter: initial;
    padding: initial;
    margin-left: 2.4rem;

    .standard-container {
      max-width: initial;
    }
  }

  .submenu__wrapper::before {
    content: none;
  }

  .submenu__container {
    display: initial;
    max-height: initial;
  }

  .submenu__content {
    display: initial;
    list-style-type: none;
    padding-left: unset;
    width: 100%;
  }

  .submenu__references-item,
  .submenu__item {
    font-size: initial;
    margin: initial !important;
    padding: initial !important;

    .submenu__references-link,
    .submenu__link {
      color: #000;
      display: inline-block;
      font-size: 0.75rem;
      padding: 0.5rem 0 0.5rem;
      text-decoration: none;
      text-transform: uppercase;
      margin: initial;
      font-weight: initial;
    }

    .submenu__references-link {
      padding-left: 0.6rem;
    }

    .submenu__link::after {
      content: none;
    }
  }
}


/***************************/
/***** OTHER ***************/
/***************************/

.site-header__navigation-item {
  color: inherit;
  display: inline-block;
  font-weight: 700;
  padding: 0.78125rem 1.2rem;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1rem;
}

@media (min-width: 90rem) {
  .site-header__navigation-item {
    font-size: 1.1875rem;
  }
}

.site-header__navigation-item--active {
  color: var(--primary);
}

@media not all and (max-width: 62rem) {
  .site-header__navigation-item--with-submenu {
    &:hover {
      & > .site-header__navigation-item::before {
        width: 100%;
      }
      & > .submenu__wrapper {
        display: block !important;
      }
    }
    & > .site-header__navigation-item::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;

      content: "";
      background: var(--primary);
      height: 0.3125rem;
      transition-duration: var(--transition-duration);

      width: 0;
    }
  }
}

/***************************/
/*** SITE HEADER - MOBILE **/
/***************************/

.submenu-mobile__opener,
.submenu-mobile {
  display: none;
}

@media (max-width: 62rem) {

  .submenu-mobile__opener {
    display: block;
  }

  .site-header__navigation {
    background: #fff;
    bottom: 0;
    display: none;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 1rem;
    position: absolute;
    right: 0;
    top: 100%;
    width: 18rem;
    height: calc(100vh - 100%);
  }

  .site-header__navigation::before {
    background: #fff;
    bottom: 0;
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.4);
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    width: 18.5rem;
    z-index: -1;
  }

  .site-header__navigation-item--with-submenu {

    padding-right: 2rem;
    position: relative;
  }

  .submenu-mobile__opener {
    background: none;
    border: none;
    padding: 1.25rem 1.25rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }


  .submenu-mobile__opener {
    &::after {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path fill='none' stroke='black' stroke-width='1' d='M1,3L5,7L9,3'/></svg>");
      background-position: 0.5rem 0.25rem;
      background-repeat: no-repeat;
      background-size: 1.25rem;
      bottom: 0.25rem;
      content: "";
      position: absolute;
      right: 0.35rem;
      top: 0.25rem;
      width: 2rem;
    }
  }

  .submenu-mobile {
    list-style-type: none;
    padding-left: unset;
  }

  .submenu-mobile__link {
    color: #000;
    display: inline-block;
    font-size: 0.75rem;
    //margin-left: 3rem;
    padding: 0.5rem 0 0.5rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  .submenu-mobile__link--with-image {
    align-items: center;
    display: inline-flex;
  }

  .submenu-mobile__image {
    float: left;
    margin-right: 1rem;
    width: 5rem;
  }

  .submenu-mobile__separator {
    color: #000;
    font-weight: 700;
    //margin-left: 2.5rem;
    padding: 0.5rem 0 0.5rem;
    text-transform: uppercase;
  }
  .submenu-mobile__separator a{
    color: inherit;
    text-decoration: inherit;
  }
}

/***************************/
/***** SUBMENU *************/
/***************************/



@media (min-width: 82rem) {
  .submenu__container {
    justify-content: space-between;
  }

  .submenu__content {
    width: 66%;
  }
}


.submenu__link--no-arrow::after {
  display: none;
}

.submenu__link-more {
  color: var(--primary);
  font-weight: 700;
  padding-right: 2rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap; /* keep arrow after link text from jumping to new line */
}

.submenu__link-more::after {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path stroke='%23db0021' fill='none' stroke-width='2' d='M3,1L7,5L3,9'/></svg>");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  width: 2rem;
}


/***************************/
/***** REFERENCES **********/
/***************************/

.submenu__references-wrapper {
  width: 33%;
  margin-top: 0.75rem;
}

.submenu__references {
  list-style-type: none;
  padding-left: 0;
}

.submenu__references-item {
  display: flex;
  margin-bottom: 2rem;
  padding-top: 0.5rem;
}

.submenu__references-image {
  flex-shrink: 0;
  margin-right: 1rem;

  img {
    object-fit: contain;
    object-position: top;
    width: 11.75rem;
    height: 7.83rem;
  }
}

.submenu__references-text {
  color: #000;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.submenu__references-text p {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.submenu__references-link {
  font-size: 1.1875rem;
  font-weight: 700;
  margin-left: 0;
  padding: 0.25rem 0;
}

@media (max-width: 62rem) {
  .submenu__references-wrapper {
    width: initial;
    margin-top: initial;

    .submenu__link {
      font-size: 1rem;
      padding: 0.5rem 0 0.5rem;
      margin: 0;
    }
  }

  .submenu__references-text {
    width: 100%;
  }
}
