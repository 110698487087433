/**
 * map
 *
 * Contains all styling for the map of the site.
 */
#map {
  display: none;
  width: 100%;
  height: 100vh;
  max-height: 100rem;
}
