/***************************/
/***** ICONS ***************/
/***************************/

.icons {
  float: right;
}

//todo
.icons__placeholder {
  height: 2.5rem;
  width: 7.5rem;
  object-position: -5rem;
  object-fit: cover;
}

/***************************/
/***** CONTENT HEADER ******/
/***************************/

.main__header {
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  section {
    margin-top: auto;
    margin-bottom: 0;
  }

  img,
  picture {
    height: auto;

    &:not(.no-height) {
      max-height: 46.875rem;
    }

    width: 100%;

    object-fit: cover;

    // &:not(.no-filter) {
    //   filter: brightness(90%);
    // }
  }

  section,
  img,
  picture {
    grid-column: 1;
    grid-row: 1;
  }

  h1 {
    text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  }
}

@media (min-width: 62rem) {
  .main__header {
    img,
    picture {
      &:not(.no-height) {
        height: 46.875rem;
      }
    }

    h1 {
      padding-bottom: var(--helper-50);
    }
  }
}

/*******************************/
/**CONTENT HEADER Landing Page**/
/******************************/

.landingpage__header {
  position: relative;
  img,
  picture {
    height: 28rem;

    width: 100%;

    object-fit: cover;
  }
  .pill {
    border: none;
    position: absolute;
    left: 5%;
    top: 80%;
  }

  h1 {
    position: absolute;
    left: 5%;
    top: 20%;
    color: var(--white);
    width: 70%;
    font-size: 3.5rem;
  }

  .cta-red {
    background-color: var(--primary);
    color: var(--white);
  }
  .cta-gray {
    background-color: var(--secondary);
  }
}

@media (min-width: 90rem) {
  .landingpage__header {
    img,
    picture {
      height: 45rem;
    }
    h1 {
      font-size: 5rem;
      width: 50%;
    }
  }
}

@media (max-width: 43rem) {
  .landingpage__header {
    img,
    picture {
      height: 22rem;
    }
    h1 {
      top: 10%;
      font-size: 1.7rem;
    }
  }
}

/***************************/
/****** START HEADER *******/
/***************************/

.start-header-container {
  background: linear-gradient(90deg, var(--secondary) 0, 5%, white);
}

.start__header {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  max-width: 92.5%;
  margin: 0 auto;

  section {
    grid-column: 1;
    align-self: center;
  }

  .swiper-wrapper {
    padding-top: var(--helper-25);
  }

  @media (max-width: 43rem) {
    grid-template-columns: 65% 72%;
    h3 {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 20rem) {
    h3 {
      font-size: 1rem;
    }
  }
}

@media (min-width: 90rem) {
  .start__header {
    max-width: 83.75rem;
  }
}

.start-img-container {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  width: 50%;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.start-img-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

/***************************/
/***** FANCY UNDERLINE *****/
/***************************/

.underline-secondary {
  background: linear-gradient(var(--highlight-gray), var(--highlight-gray));
  background-origin: padding-box;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 0.625rem;
  padding-bottom: 0.3125rem;
  margin-bottom: var(--helper-15);
  margin-top: var(--helper-15) !important;
}

.underline-primary {
  background: linear-gradient(var(--primary), var(--primary));
  background-origin: padding-box;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100% 0.625rem;
  padding-bottom: 0.5rem;
  margin-bottom: var(--helper-50);
  margin-top: var(--helper-15) !important;
}

.background-primary {
  .underline-primary {
    background: linear-gradient(var(--white), var(--white));
  }
}

.text-box_underline-primary {
  //font-size: 0.75rem;
  width: 100%;
}

/***************************/
/***** HIGHLIGHTS **********/
/***************************/

.highlights {
  /*align-items: flex-start; /* items should not take 100% width */
  display: flex;
  /*flex-direction: column; /* put one item per line */
  flex-wrap: wrap;
  list-style-type: none;
  max-width: 71.25rem;
  padding-left: 0;
  margin-bottom: -0.9375rem;

  &:not(:first-child) {
    margin-top: var(--helper-50);
  }
}

.highlights__item {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: calc(0.75vw + 0.7rem);
  font-weight: 700;
  margin-bottom: 0.9375rem;
  margin-right: 0.9375rem;
  position: relative;
  .highlights__link {
    cursor: pointer;
  }
}
.highlights__item.active {
  .highlights__link {
    background: var(--primary);
  }
}

@media (min-width: 90rem) {
  .highlights__item {
    font-size: 1.5rem;
  }
}

.highlights__link {
  color: inherit;
  text-decoration: inherit;
  font-size: var(--font-size-p);
  background: var(--highlight-gray);
  padding: 0.8rem 2rem 0.5rem 1rem;

  svg {
    position: absolute;
    right: 0.8rem;
    width: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.highlights__link:hover {
  background: var(--primary);
}

.highlights-sublist-wrapper,
.offer-wrapper {
  width: 100%;
  margin-bottom: var(--helper-25);

  .highlights-sublist,
  .offer-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    color: var(--gray);
    padding-left: 0;

    &.column {
      flex-direction: column;
    }

    .highlights-sublist__item,
    .offer-item {
      margin-right: var(--helper-15);
      line-height: 2;
      font-size: var(--font-size-p);
      font-weight: 700;
      position: relative;

      .highlights-sublist__link,
      .offer-link {
        color: inherit;
        text-decoration: none;
        position: relative;

        &:hover,
        &.active {
          color: var(--primary);
        }

        &:hover::before,
        &.active::before {
          width: 100%;
        }
        &::before {
          background: var(--primary);
          top: 100%;
          content: "";
          height: 0.3125rem;
          left: 0;
          position: absolute;
          transition-duration: var(--transition-duration);
          width: 0;
        }
      }
    }
  }
}

@media (max-width: 43rem) {
  .highlights {
    flex-direction: column;

    .highlights__item {
      margin-right: 0;

      .highlights__link {
        width: 100%;
      }
    }
  }
}

/***************************/
/***** JOBS ****************/
/***************************/

.jobs-images__item {
  height: 8rem;
}

.jobs-images__item img {
  height: 100%;
  object-fit: cover;
  object-position: 20%;
  width: auto;
}

/***************************/
/***** HERO IMAGE **********/
/***************************/

.hero--big {
  background: var(--darkgray);
  display: block;
  max-height: 37.5rem;
  min-height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

.hero--small {
  border: 1px solid #222;
  display: block;
  height: 9.375rem;
  margin-top: -5rem;
  object-fit: cover;
  object-position: top left;
  position: relative;
  top: -50%;
  width: 9.375rem;
}

@media (min-width: 62rem) {
  .hero--big {
    height: 37.5rem;
  }
}

/***************************/
/***** REFERENCES **********/
/***************************/

.references-list {
  display: grid;
  gap: 3.125rem;
  grid-template-columns: repeat(auto-fit, minmax(0, 20rem));
  grid-template-rows: auto;
  list-style-type: none;
}
.references-list__item {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0 1px;
}

.references-list__info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.references-list__info .underline-primary {
  margin-bottom: 0;
}
.references-list__short-description {
  min-height: 4rem;
  margin-bottom: 0.5rem;
}
.references-list__link {
  align-self: flex-start;
  background-color: var(--red-references);
  color: white;
  display: inline-block;
  font-size: 0.975rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: auto; /* push to the bottom of the container */
  text-decoration: none;
}

/***************************/
/***** TESTIMONIAL *********/
/***************************/
.testimonial {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: var(--helper-50) 0;
}

.testimonial__image {
  border: 1px solid rgb(170, 165, 165);
  float: left;
  width: 27.8125rem;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
}

.testimonial__text {
  color: inherit;
}

.white_border {
  border: 1px solid #fff;
}

.testimonial__text-box {
  margin-bottom: 0;
  max-width: unset;
  align-self: flex-start;

  &:only-child {
    text-align: center;
    margin: auto;
    max-width: 53.125rem;
  }
}

.testimonial__text-box {
  h4 {
    padding-top: var(--helper-50);
  }
  p {
    font-size: var(--font-size-p);
    line-height: 1.65;
    font-weight: 400;
    padding-top: var(--helper-25);
  }
}

@media (max-width: 43rem) {
  .testimonial__image {
    max-width: 90%;
  }

  .testimonial__text-box {
    max-width: 90%;
  }
}

@media (min-width: 62rem) {
  .testimonial {
    flex-direction: row;
  }
  .testimonial img {
    margin-right: var(--helper-50);
  }
  .testimonial__text-box {
    h4 {
      padding-top: 0;
    }
  }
}

/***************************/
/***** TESTIMONIALS ********/
/***************************/

.testimonials__item {
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: center;
  padding: 2rem;
}

/***************************/
/***** Coperate Values ********/
/***************************/

.corporate_values__item {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column !important;
  margin-top: 1.5rem;
}

@media (min-width: 60rem) {
  .corporate_values__item {
    flex-direction: row !important;
  }
}
.corporate_value__image {
  background: var(--darkgray);
  border: 1px solid #222;
  display: block;
  height: 8rem;
  object-fit: cover;
  object-position: top left;
  position: relative;
  width: 8rem;
  margin: 0 5rem 1rem 0;
}

.corporate_value__short-description {
  font-size: 1rem;
  line-height: 1.5;
  max-width: 45rem;
}

/***************************/
/***** JOBS ****** *********/
/***************************/

.jobs {
  display: grid;
  gap: var(--helper-25);
  grid-template-columns: repeat(auto-fit, minmax(19.5rem, 1fr));
  grid-auto-rows: 1fr;
  list-style-type: none;
  justify-content: space-between;
  padding: initial;
}

.jobs__offer {
  display: block;
  text-decoration: none;

  font-size: 0.9rem;
  padding: var(--helper-32) var(--helper-32) var(--helper-75);
  line-height: 1.5;
}

@media (min-width: 43rem) {
  .jobs__offer {
    min-height: 28rem;
  }
}

.jobs__offer :not(:first-child):not(small) {
  margin-top: var(--helper-32);
}

.red_bar {
  height: 3.125rem;
  background-color: var(--red-jobs);
  color: var(--jobs-text-color);
  padding: 0 1rem;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 35.12rem) {
    font-size: 14px;
  }
}

.red_bar-container {
  margin-top: var(--helper-150);
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(19.5rem, 1fr));
  grid-template-rows: auto;
  justify-content: space-between;
  height: 3.125rem;
}


.black_bar {
  background-color: var(--black) !important;
}


/***************************/
/***** SERVICES ************/
/***************************/

.services__item {
  border: 1px solid black;
  color: inherit;
  display: flex;
  height: 100%;
  padding: 0.5rem;
  text-decoration: none;
  width: 100%;
  word-break: break-all;
  height: 25rem;
  box-shadow: -5px 0 3px -2px #999;
}

.services__item__text {
  color: white;
  text-shadow: 2px 2px 4px #000;
  align-self: flex-end;
}

/***************************/
/***** ACCORDIONS **********/
/***************************/

.accordion {
  border-top: 2px solid #999;
  max-width: 100%;
  text-transform: none;
}
.accordion-opener,
.button {
  position: relative;
  background: inherit;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  text-decoration: inherit;
  text-transform: inherit;
  cursor: default;

  padding-top: var(--helper-25);
  padding-bottom: var(--helper-25);
}

.accordion-opener {
  cursor: pointer;

  &[aria-expanded]::after {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path stroke='black' fill='none' stroke-width='1.5' d='M3,1L7,5L3,9'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 2rem;
  }
  &[aria-expanded="true"]::after {
    transform: rotate(90deg);
  }
}

.accordion-opener--with-icon {
  margin-left: -4rem;
  padding-left: 4rem;
  position: relative;
}

.accordion-opener--with-icon[aria-expanded]::before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  bottom: 0;
  content: "";
  left: 1.5rem;
  position: absolute;
  top: 0;
  width: 2rem;
}

.accordion-opener--with-icon[aria-expanded="true"]::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path stroke='%23999' fill='none' stroke-width='2' d='M1,5L9,5'/></svg>");
}

.accordion-opener--with-icon[aria-expanded="false"]::before {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' width='10' height='10'><path stroke='%23999' fill='none' stroke-width='2' d='M5,1L5,9M1,5L9,5'/></svg>");
}

.accordion--last {
  border-bottom: 2px solid #999;
}

/***************************/
/***** TEXT WITH IMAGE *****/
/***************************/

.text-with-image {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: minmax(20rem, 1fr) minmax(28rem, 2fr);
  grid-template-rows: auto;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.text-with-image__image {
  border: 1px solid #ddd;
  width: 100%;
}

.text-with-image .text-box {
  max-width: 53.125rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.text-with-image .text-box p {
  margin: auto 0;
  padding: 0;
}

@media (max-width: 43rem) {
  .text-with-image {
    grid-template-columns: 1fr;
  }
}

/***************************/
/***** IMAGE BOX ***********/
/***************************/

.image-box {
  display: flex;
  flex-wrap: wrap;
  //margin-bottom: 3rem;
  margin: -0.5rem;

  figure {
    padding: 0.5rem;
    width: 40%;

    img {
      object-fit: cover;
      object-position: center;
    }

    &:not([data-ratio]) {
      .figure-wrapper {
        height: 100%;
        padding-bottom: 0 !important;
      }
    }
  }

  &.even {
    figure {
      &:nth-child(4n + 1) {
        width: 60%;
      }
      &:nth-child(4n) {
        width: 60%;
      }
    }
  }

  &.odd {
    figure {
      &:first-child {
        width: 100% !important;
      }
      &:nth-child(4n + 2) {
        width: 60%;
      }
      &:nth-child(4n + 1) {
        width: 60%;
      }
    }
  }
}

.image-box--full-width {
  margin-bottom: 0.5rem;
  width: 100%;
}

.image-box--sixty-percent {
  margin-right: 0.5rem;
  width: calc(60% - 0.5rem);
}

.image-box--forty-percent {
  width: 40%;
}

/***************************/
/***** TWO COLUMNS *********/
/***************************/

.two-columns {
  display: flex;
  flex-wrap: wrap;
  //margin: 0 -0.5rem;
}

.two-columns__first-column,
.two-columns__second-column {
  width: 50%;
  flex-grow: 1;
  padding: 0.5rem;
}

.no-padding {
  padding: 0;
}

/***************************/
/***** PROJECT *************/
/***************************/

.project {
  font-size: calc(1.5625vw + 0.5625rem);
}

@media (min-width: 60rem) {
  .project {
    font-size: 1.5rem;
  }
}

.project__header,
.project__info {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //padding: var(--helper-50) var(--helper-15) var(--helper-50) 0;

  ul {
    font-size: var(--font-size-p);
    line-height: 1.65;
    font-weight: 500;
    padding-left: var(--font-size-p);
  }

  &.narrowed {
    .small-container {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        &:not(:first-child) {
          margin-top: var(--helper-25) !important;
        }
      }
    }
  }
}

.project__header {
  .small-container {
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.project__info {
  grid-area: info;
}

.project__header {
  grid-area: header;
}

.project__label {
  margin-top: 1rem;
}

.project__image {
  grid-area: img;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 100%;
}

.characteristics {
  display: grid;
  grid-template:
    "header ." minmax(min-content, max-content)
    "info img" auto / 50%;
  grid-gap: var(--helper-50);
}

@media (max-width: 62rem) {
  .characteristics {
    grid-template:
      "header" auto
      "info" auto;
  }
}

/***************************/
/***** NEWS ****************/
/***************************/

.news {
  margin-bottom: 1rem;
}

.news__image {
  height: auto;
  width: 100%;
}

.news__text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.news__paragraph {
  font-size: 0.9rem;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

.news__link-more {
  color: #000;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
}

.bachnernews {
  display: grid;
  grid-gap: var(--helper-25);
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;

  @media (max-width: 43rem) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 43rem) and (max-width: 62rem) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.bachnernews__element {
  margin-right: var(--helper-25);
}

.bachnernews__headline {
  color: var(--primary);
  font-weight: 700;
}

.bachnernews__download {
  font-size: 0.8rem;
}

.bachnernews__image {
  box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
  border: solid var(--lightgray) 0.1rem;
}

.values {
  .content-wrapper {
    &.news-teaser {
      justify-content: flex-start;

      small {
        margin-bottom: var(--helper-15);
      }
    }
  }

  @media (max-width: 62rem) {
    .news-img {
      margin-top: 3rem;
      height: auto;
    }
  }
}

.navigation {
  margin-top: var(--helper-75) !important;

  .small-container {
    .pill {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
      }

      &.prev {
        padding-left: 2rem;
        svg {
          left: 0;
          transform: translate(75%, -50%);
        }
      }
      &.next {
        padding-right: 2rem;
        svg {
          right: 0;
          transform: translate(-75%, -50%);
        }
      }
    }
  }

  .nav-grid {
    display: grid;
    grid-gap: var(--helper-25);
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto;
  }
}

@media (min-width: 60rem) {
  .navigation {
    .nav-grid {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

      & > * {
        &:last-child {
          margin-left: auto;
        }
        &:first-child {
          margin-right: auto;
        }

        &:not(:last-child):not(:first-child) {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.news-minimal-img {
  height: 15rem;
  margin-bottom: var(--helper-15);
  width: 100%;
  object-fit: cover;
}

.news-minimal-container {
  .slider-next {
    right: calc(-1 * var(--helper-50));
  }

  .slider-prev {
    left: calc(-1 * var(--helper-50));
  }
}

@media (min-width: 60rem) {
  .news-minimal-container {
    .slider-next {
      right: -50px;
    }

    .slider-prev {
      left: -50px;
    }
  }
}

/***************************/
/***** JOB-SEARCH **********/
/***************************/

.job-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -0.25rem;

  select {
    background-color: var(--white);
    padding: 0.5rem 0.75rem;
    font-size: var(--font-size-p);
    font-weight: 700;
    //text-transform: uppercase;
    color: var(--footer-gray);
  }

  .submit {
    border: none;
  }

  a {
    text-decoration: none;
  }

  .job-search-input-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    & > select {
      //flex-grow: 1;
      margin: 0.25rem;
      //max-width: 17rem; //TODO
      overflow: auto;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    & > * {
      margin: 0.25rem;
    }
  }

  .checkbox-label {
    padding: 0.5rem 0.75rem;
    font-size: var(--font-size-p);
    font-weight: 700;
    color: var(--footer-gray);
    display: flex;
    align-items: center;

    input {
      width: var(--font-size-p);
      height: var(--font-size-p);
      margin-right: 0.5rem;
    }
  }
}

.job-search-form {
  a {
    margin: var(--helper-25) 0 0;
  }
}

.job-search-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--helper-15);
}

@media (min-width: 62rem) {
  .job-search-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--helper-15);
  }
}
/***************************/
/***** LOCATION   **********/
/***************************/

.education,
.faq,
.departments,
.location {
  margin-top: 0 !important;

  button {
    width: 100%;
    font-size: var(--font-size-h4);
    font-weight: 900;
    line-height: 1.2;
    padding-right: 2rem;
  }
  .text-box p {
    margin-top: unset;
  }
}
.location__inner {
  display: flex;
  flex-direction: column;

  //&[hidden] {
  //  display: none;
  //}

  img {
    width: 27.8125rem;
    height: 18.75rem;
    margin-bottom: var(--helper-25);
    object-fit: cover;
  }

  .location__offers {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ul {
      padding-left: 1rem;
      margin-bottom: var(--helper-25);
    }

    .pill {
      align-self: flex-start;
      text-decoration: none;
    }
  }

  .location__info {
    width: 100%;
    margin: auto 0;
  }
}

@media (min-width: 62rem) {
  .location__inner {
    flex-direction: row;

    img {
      margin-right: var(--helper-50);
      margin-bottom: 0;
    }
  }
}

/***************************/
/***** LOCATION MAP ********/
/***************************/

.map-container {
  margin-bottom: 6.625rem;
}

.map-description {
  padding: 1.5rem;
  background: black;
  color: white;
  border-radius: 10px;
  min-width: 100px;
  max-width: 500px;
  position: absolute;
  display: none;
}

.map-description.map-description-active {
  display: block;
}

.close-tooltip {
  width: 25px;
  background: none;
  border: none;
  float: right;
  display: none;
}

@media (max-width: 43rem) {
  .map-description {
    padding: 10px;
  }

  .close-tooltip {
    display: block;
  }
}

/***************************/
/***** VALUES **************/
/***************************/

.values {
  display: flex;
  flex-direction: column;

  &.centered {
    .content-wrapper {
      justify-content: center;
    }
  }

  & > *:not(:first-child),
  &:not(:first-child) {
    margin-top: var(--helper-50);
  }

  img {
    width: 27.8125rem;
    height: 18.75rem;
    object-fit: contain;
    object-position: 0 0;

    @media (max-width: 43rem) {
      height: auto;
    }
  }

  img.values-vertical {
    height: 41.25rem;

    @media (max-width: 43rem) {
      height: auto;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > *:not(:first-child) {
      margin-top: var(--helper-25);
    }
    & > a {
      margin: 0 auto 0 0;
      text-decoration: none;
    }

    & .small-container > * {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: var(--helper-25);
      }
    }
  }

  .text-box {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: var(--helper-50);
    }
  }
}

@media (min-width: 62rem) {
  .values {
    display: flex;
    flex-direction: row;

    &.reversed {
      justify-content: flex-end;
      flex-direction: row-reverse;

      & > .content-wrapper:not(:first-child) {
        margin-right: var(--helper-50);
      }

      & > a,
      & > img {
        margin-right: 0;
        margin-left: var(--helper-50);
      }
    }

    &:not(.reversed) {
      & > .content-wrapper:not(:last-child) {
        margin-right: var(--helper-50);
      }
    }

    & > a,
    & > img {
      margin-right: var(--helper-50);
    }

    & > *:not(:first-child) {
      margin-top: initial;
    }

    img {
      object-position: center;
    }
  }
}

.values {
  &.divider:not(:last-of-type) {
    border-bottom: 0.3125rem solid var(--highlight-gray);
    padding-bottom: var(--helper-50);
  }
}

/***************************/
/***** BENEFITS   **********/
/***************************/

.benefits {
  display: grid;
  grid-template-columns: minmax(1fr, 53.125rem);
  grid-template-rows: auto;
  grid-gap: var(--helper-100);

  &:not(:first-child) {
    margin-top: var(--helper-50);
  }
}

@media (max-width: 62rem) {
  .benefit__inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
    text-align: center;
    margin: auto;
  }
  .benefit-icon {
    height: auto;
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    filter: brightness(0) saturate(100%) invert(13%) sepia(76%) saturate(7140%)
      hue-rotate(15deg) brightness(100%) contrast(93%);
  }
  .benefit__inner {
    max-width: 35rem;
    margin: auto;
  }

  .benefit__inner-front {
    .benefit__inner-wrapper {
      .benefit-title {
        display: none;
      }
    }
  }
  .benefit__inner-back {
    .benefit__inner-wrapper {
      display: grid;
      gap: var(--helper-50);
      margin-top: var(--helper-25);
    }

    h4 {
      margin-top: auto !important;
    }
  }

  .benefit-title,
  .benefit-description {
    display: none;
  }
}

@media (min-width: 62rem) {
  .benefits {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0;
    width: 65%;
    margin-bottom: -2rem;
  }

  .benefit {
    padding-top: 70%;
    perspective: 10000px;
  }
  .benefit-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .benefit__inner {
    cursor: pointer;

    .benefit-icon {
      height: auto;
      width: 5rem;
      margin-left: auto;
      margin-right: auto;
      filter: brightness(0) saturate(100%) invert(13%) sepia(76%)
        saturate(7140%) hue-rotate(15deg) brightness(100%) contrast(93%);
    }

    .benefit__inner-front,
    .benefit__inner-back {
      position: absolute;
      width: 100%;
      height: 100%;

      .benefit__inner-wrapper {
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        word-break: break-word;
        text-align: center;
        margin: 0;

        h6 {
          font-size: 0.9rem;
        }
      }

      .benefit-inner-title {
        display: none;
      }
    }
  }

  .benefit:hover {
    .benefit-inner-title {
      display: block;
    }
    .benefit-icon {
      display: none;
    }
  }

  .benefit-description {
    margin-top: var(--helper-15) !important;
  }

  .benefit-title,
  .benefit-description {
    display: none;
  }

  .show {
    display: block;
  }
}

/***************************/
/***** REQUEST *************/
/***************************/

.request-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: var(--helper-50);

  label {
    font-weight: 700;
    display: block;
    margin-bottom: var(--helper-15);
  }

  .input-wrapper {
    flex-grow: 1;
    min-width: 45%;
    margin-right: var(--helper-25);
  }

  .textarea-wrapper {
    width: 100%;
  }

  .textarea {
    width: 100%;
    height: 20rem;
    resize: none;
  }

  .radio-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  .select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--helper-50);
  }

  .select-element {
    font-size: 0.8rem;
    font-family: sans-serif;
    padding: 0.6em 1.4em 0.5em 0.8em;
    margin: 0;
    border: 1px solid var(--gray);
    border-radius: 0.1rem;
    background-color: var(--white);
    outline: none;
  }

  .input,
  .textarea {
    width: 100%;
    margin-bottom: var(--helper-15);
    font-size: var(--font-size-p);
    line-height: 2;
    padding: 5px;

    &:invalid:focus {
      outline-color: red !important;
    }
  }

  .radio-wrapper-input {
    display: flex;
    flex-direction: row;
    margin-right: 2rem;

    .margin-right {
      margin-right: 0.5rem;
    }
  }

  .radio {
    justify-items: flex-start;
    margin-left: 0.3rem;
    margin-bottom: 1rem;
  }

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border: 2px solid var(--highlight-gray);
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;
  }

  input[type="radio"]:checked {
    background: var(--highlight-gray);
  }

  .input-small {
    width: 50%;
    height: 2rem;
    margin-left: 2rem;
  }

  .input-wrapper,
  .textarea-wrapper,
  .radio-wrapper {
    margin-bottom: var(--helper-50);
  }

  .checkbox-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: var(--helper-50);

    input[type="checkbox"] {
      margin: 0 1rem;


      &:invalid:focus {
        outline: 1px solid red;
      }
    }

    label {
      margin-top: 0.8rem;
    }
  }

  .submit {
    border: none;
  }
}

@media (max-width: 43rem) {
  .request-form {
    flex-direction: column;

    .input-wrapper {
      width: 100%;
      margin-right: 0;
    }

    .textarea {
      height: 10rem;
    }
    .checkbox-wrapper {
      input[type="checkbox"] {
        margin: 0 0.5rem;
      }
    }
  }
}

/***************************/
/***** TEAM ****************/
/***************************/

.team-list {
  display: grid;
  gap: var(--helper-50) var(--helper-25);
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.team-list .team__member .team__inner .team__info dl dd:first-of-type {
  padding-bottom: var(--helper-50);
}

@media (min-width: 90rem) {
  .team-list {
    grid-template-columns: 1fr 1fr;
  }
}

/***************************/
/***** STORY ELEMENTS ******/
/***************************/

blockquote {
  font-size: var(--font-size-h4);
  font-style: italic;
  line-height: 1.5;
  font-weight: 500;
  padding: 0 0 0 var(--helper-25);
  border-left: 3px solid var(--darkgray);

  footer {
    margin-top: var(--helper-50);
    font-size: 0.8em;
    color: var(--footer-gray);
  }
}

figcaption {
  font-size: var(--font-size-p);
  line-height: 1.65;
  font-weight: 400;
  padding-top: var(--helper-15);
}

/***************************/
/***** TILES ***************/
/***************************/

.tow-tiles {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: var(--helper-50);

  margin-top: var(--helper-50);
  margin-bottom: var(--helper-50);

  a {
    text-decoration: none;
  }
}

.tile__simple {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  img {
    height: auto;
    max-height: 28.125rem;
    min-height: 100%;

    width: 100%;

    object-fit: cover;
    filter: brightness(80%);

    box-shadow: 5px 5px 3px -2px var(--gray);
  }
  h3 {
    color: var(--white);
    margin: auto var(--helper-25) var(--helper-25);
    hyphens: auto;
    z-index: 1;
  }

  img,
  h3,
  figure {
    grid-column: 1;
    grid-row: 1;
  }
}

.tile__simple-hover {
  overflow: hidden;
  img {
    transition: transform 0.2s;
  }
}

.tile__simple-hover:hover img {
  transform: scale(1.2);
}

@media (min-width: 62rem) {
  .tow-tiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

/***************************/
/***** LANGUAGE DROPDOWN ***/
/***************************/

.site-toolbar {
  display: flex;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  flex-wrap: wrap;

  img {
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    //opacity: 0.5;
  }
}

.dropdown {
  position: relative;
}

.dropdown-trigger-languages {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
}

.dropdown-item {
  color: inherit;
  display: block;
  border: 1px solid var(--lightgray);

  img {
    max-width: unset;
  }

  span {
    padding: 0.5rem;
    word-break: keep-all;
  }
}

.dropdown-content a:hover {
  background-color: var(--lightgray);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-item-link {
  //min-width: 10rem;
  //padding: 0.5rem;
  background-color: var(--white);
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--font-size-p);
  line-height: 1.5;
  font-weight: 500;
}

//TODO
//.flag-icon-placeholder {
//  width: 2.5rem;
//  height: 2.5rem;
//  object-position: left;
//  object-fit: cover;
//}
.search-icon-placeholder {
  width: 2.5rem;
  height: 2.5rem;
  object-position: -2.5rem;
  object-fit: cover;
}

/***************************/
/***** SEARCH **************/
/***************************/

e.search-header {
  font-size: var(--font-size-h4);
  font-weight: 700;
}

.search-list {
  list-style-type: none;
}

.search-item {
  .search-item__link {
    font-size: var(--font-size-h4);
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    display: block;

    background: linear-gradient(var(--primary), var(--primary));
    background-origin: padding-box;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 100% 0.3125rem;
    padding-bottom: 0.3125rem;
  }

  .search-item__text {
    & > p {
      margin-bottom: var(--helper-25);
    }
  }

  margin-top: var(--helper-50);
}

.search-result-form {
  .input {
    font-size: var(--font-size-p);
    line-height: 2;
    padding: 5px;
    margin-right: var(--helper-50);
  }
  .submit {
    border: none;
  }
}

/***************************/
/***** JOB-OFFER ***********/
/***************************/

.job-offer {
  button {
    width: 100%;
    font-size: var(--font-size-h4);
    font-weight: 900;
    line-height: 1.2;
  }

  a {
    text-decoration: none;

    h4 {
      margin-bottom: var(--helper-25);
    }
  }

  p {
    margin-top: var(--helper-15);
  }
}
.job-offer__inner {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;

  span,
  p,
  ul,
  ol {
    line-height: 1.65 !important;
  }

  & *[style] {
    font-size: inherit !important;
    color: inherit !important;
  }

  p,
  ul,
  ol {
    margin-top: var(--helper-15);
    font-size: var(--font-size-p) !important;
  }

  & .small-container {
    width: 100%;

    & > div:not(.job-offer__info) {
      margin-top: var(--helper-25);
    }
  }

  .job-offer__headline {
    font-weight: 900;
    line-height: 1.65;
  }

  .job-offer__icon {
    width: 1.5rem;
    margin-right: 0.5rem;
    text-align: center;

    img {
      height: 1.5rem;
      width: auto;
      margin: auto;
    }
  }

  .job-offer__lists {
    display: flex;
    flex-wrap: wrap;
    & > div {
      flex: 50%;
      min-width: 26.5625rem;
    }
    ul {
      li {
        font-size: var(--font-size-p);
        line-height: 1.65;
      }

      padding-left: var(--font-size-p);
      margin-right: var(--helper-15);
    }
  }

  .job_offer__apply {
    display: block;

    span {
      margin-left: var(--helper-15);
      margin-right: var(--helper-15);
    }
  }

  .pill {
    text-align: center;
    text-decoration: none;
  }

  .team__member {
    margin-top: var(--helper-25);
  }

  .job-offer__info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    gap: var(--helper-25);
    margin-bottom: var(--helper-50);
    margin-top: var(--helper-50);

    & > div {
      display: flex;
    }
  }

  ul {
    font-size: var(--font-size-p);
    line-height: 1.65;
    font-weight: 400;
    padding-left: var(--font-size-p);
  }

  .text-box {
    & * {
      line-height: 1.65;
      font-size: var(--font-size-p);
    }
  }
}

.job-offer__header {
  position: relative;

  img {
    width: 100%;
  }

  div.standard-container {
    h1 {
      position: absolute;
      bottom: 0;
      color: var(--white);
    }
  }
}

.job-offer__title {
  font-size: var(--font-size-h3);
}

@media (min-width: 77.5rem) {
  .job-offer__inner {
    .job_offer__apply {
      display: flex;
      flex-direction: column;

      span {
        margin-top: var(--helper-50);
        margin-bottom: var(--helper-15);
        text-align: center;
      }
    }
    .job-offer__info {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      gap: 0;
      margin-top: 0;
    }
  }
}

/***************************/
/***** offer ***************/
/***************************/

.offer {
  & section:first-of-type {
    margin-top: var(--helper-75);
  }
}

/***************************/
/***** Other ***************/
/***************************/

.pill {
  box-shadow: 2px 4px 7px var(--gray);
  display: inline-block;
  font-size: 0.975rem;
  font-weight: bold;
  padding: 0.75rem 1.75rem;
  cursor: pointer;
  transition: transform 0.2s;

  &.centered {
    text-align: center;
  }
}

.pill:hover {
  transform: scale(1.1) !important;
}

a {
  &.pill {
    text-decoration: none;
  }
}

.background-secondary {
  &.pill {
    box-shadow: 2px 4px 7px var(--darkgray);
  }
}

.sticky {
  //position: sticky;
  top: 0; //calc(2 * var(--helper-25) + 4.375rem)
  padding: var(--helper-15) var(--helper-15) var(--helper-25);
  background-color: var(--white);
  z-index: 2;
  transition-duration: calc(2 * var(--transition-duration));
}

@media (max-width: 62rem) {
  .sticky {
    top: calc(2 * var(--helper-25) + 2 * var(--helper-100) + 6px);
  }
}

.separated-list {
  display: flex;
  flex-wrap: wrap;

  & > *:not(:last-child):after {
    content: "\2022";
    margin: 0.2em;
    font-weight: 500;
  }
}

@media (max-width: 77.5rem) {
  .hide-sticky {
    transform: translateY(-100%);
  }
}
.word-break__word {
  word-wrap: break-word;
  overflow-wrap: break-word;

  hyphens: auto;
}

*[hidden] {
  display: none;
}

b,
strong {
  font-weight: 900;
}

strong {
  font-size: 1.1em;
}

.list {
  font-size: var(--font-size-p);
  .headline {
    font-size: inherit;
    font-weight: 900;
    line-height: 1.65;
  }

  ul {
    font-size: inherit;
    li {
      font-size: inherit;
      line-height: 1.65;
    }

    padding-left: 1em;
    margin-right: var(--helper-15);
  }
}

ul {
  font-size: inherit;
  li {
    font-size: inherit;
  }

  padding-left: 1em;
}

.description-list {
  dt,
  dd {
    line-height: 1.6;
    white-space: pre-wrap;
    font-size: var(--font-size-p); //calc(1.5625vw + 0.5625rem);
    word-break: break-word;
  }

  dt {
    float: left;
    clear: left;
  }

  dd {
    a {
      text-decoration: none;
    }
  }

  dl {
    margin-bottom: var(--helper-25);
    word-break: break-all;
  }
}

.logo {
  width: 18.125rem;
}

.is-danger {
  color: red;
}

.faq-link {
  font-size: var(--font-size-p);
  font-weight: 900;
}

.unsupported-browser {
  text-align: center;
  font-weight: 700;
  padding: 3rem 1rem;
  font-size: var(--font-size-h4);
}

.file {
  video {
    width: 100%;
  }
}

.heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-image {
  height: 64px;
  margin: 0 !important;

  @media (max-width: 43rem) {
    display: none;
  }
}

.heading-image-link {
  @media (max-width: 43rem) {
    display: none;
  }
}
/***************************/
/***** TIMELINE ************/
/***************************/

.timelines {
  text-align: center;
}

.timeline {
  text-align: center;
  margin: var(--helper-100) 0;

  .pill {
    box-shadow: none;
    cursor: auto;
    background-color: var(--primary);
    color: var(--white);
  }

  .decade-header {
    display: inline-block;

    .underline-primary {
      margin-bottom: 0;
    }
  }

  .decade {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      border: 1px dashed var(--lightgray);
      transform: translateX(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      border-style: solid;
      //border-color: var(--highlight-gray);
      transform: translate(-50%, 32px);
      border-width: 16px 5px;
      border-color: var(--lightgray) transparent transparent;
    }

    .year {
      position: relative;
      word-break: break-word;
      hyphens: auto;

      h4 {
        position: relative;

        &::before {
          position: absolute;
          content: "";
          height: 25px;
          width: 25px;
          background-color: var(--primary);
          border-radius: 50%;
          display: inline-block;
        }
      }

      img {
        width: 75%;
      }

      & > * {
        padding: var(--helper-15) var(--helper-50);
      }

      &:nth-child(even) {
        text-align: left;
        margin-left: 50%;

        img {
          left: 0;
        }

        h4::before {
          transform: translate(-50%, 0);
          left: 0;
        }
      }

      &:nth-child(odd) {
        text-align: right;
        margin-right: 50%;

        img {
          right: 0;
        }

        h4::before {
          transform: translate(50%, 0);
          right: 0;
        }
      }
      &:first-child {
        padding-top: var(--helper-50);
      }
    }
  }
}

@media (max-width: 43rem) {
  .timeline {
    text-align: left;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;

    .decade {
      margin-left: 1.5rem;

      &::after,
      &::before {
        left: 0;
      }

      .year {
        margin-left: 0 !important;
        margin-right: 0 !important;

        padding-top: var(--helper-50);

        text-align: left !important;

        h4::before {
          transform: translate(-50%, 0) !important;
          left: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 43rem) {
  .year {
    img {
      width: 100% !important;
    }
  }
}

/***************************/
/***** ENTRY POSSIBILITIES */
/***************************/

.entry-possibility__media {
  &:not(:first-child) {
    margin-top: var(--helper-50);
  }

  video,
  img {
    width: 100%;
  }
}

/***************************/
/***** Career Pages ********/
/***************************/

@media (min-width: 77.5rem) {
  .career-page {
    span[id] {
      scroll-margin-top: 10rem;
    }
  }
}

/***************************/
/***** Layout **************/
/***************************/

.layout {
  .standard-container {
    & > *:not(.small-container):not(.header-container),
    & > .header-container > *,
    & > .small-container > * {
      &:not(:first-child) {
        margin-top: var(--helper-50);
      }
    }

    & > .header-container {
      & > .small-container > * {
        &:not(:first-child) {
          margin-top: var(--helper-50);
        }
      }
    }

    & > .small-container,
    & > .header-container,
    & > .file,
    & > .benefits {
      &:not(:first-of-type) {
        margin-top: var(--helper-50);
      }
    }
  }
  .small-container {
    .text-box {
      &:not(:last-child) {
        margin-bottom: var(--helper-50);
      }
    }
  }

  .slider-container {
    & > * {
      &:not(:last-child) {
        margin-bottom: var(--helper-50);
      }
    }

    section:not(:first-child) {
      margin-top: var(--helper-50);
    }
  }

  .columns > .column {
    & > *,
    & > .header-container > *,
    & > .small-container > * {
      &:not(:first-child) {
        margin-top: var(--helper-50);
      }
    }
  }

  .narrowed {
    .small-container {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        &:not(:first-child) {
          margin-top: var(--helper-15);
        }
      }
      .text-box,
      .text-box p,
      ul,
      ol {
        margin-top: var(--helper-15) !important;
        margin-bottom: 0 !important;
      }
    }

    &.project__info > * {
      &:not(:first-child) {
        margin-top: var(--helper-15);
      }
    }
  }

  ul,
  ol {
    &:not(.highlights) {
      &:not(:first-child) {
        margin-top: var(--helper-50);
      }
      &:not(:last-child) {
        margin-bottom: var(--helper-50);
      }
    }
  }
}

.columns {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
  grid-gap: var(--helper-50);

  .standard-container,
  .header-container {
    max-width: unset;
  }

  .slider-next {
    right: 0 !important;
    transform: translate(100%, -50%) !important;
  }

  .slider-prev {
    left: 0 !important;
    transform: translate(-100%, -50%) !important;
  }

  &.reversed > * {
    &:first-child {
      order: 2;
    }

    &:last-child {
      order: 1;
    }
  }
}

@media (min-width: 62rem) {
  .columns {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/***************************/
/***** figure **************/
/***************************/

figure {
  img {
    width: 100%;
    height: auto;
  }

  &[data-crop] {
    .figure-wrapper {
      img {
        object-fit: cover;
      }
    }
  }
}

figure:not([data-ratio="auto"]) {
  &[data-ratio="1/1"] {
    .figure-wrapper {
      padding-bottom: 100%;
    }
  }

  &[data-ratio="4/3"] {
    .figure-wrapper {
      padding-bottom: 75%;
    }
  }

  &[data-ratio="3/2"] {
    .figure-wrapper {
      padding-bottom: 66.667%;
    }
  }

  &[data-ratio="21/9"] {
    .figure-wrapper {
      padding-bottom: 42.86%;
    }
  }

  .figure-wrapper {
    position: relative;
    padding-bottom: 56.25%;

    iframe,
    img,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

/***************************/
/***** news page ***********/
/***************************/

.news-page {
  .date-published + section {
    margin-top: var(--helper-15);
  }
}

.pagination {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
}

.prev {
  text-decoration: none;
}

.next {
  text-decoration: none;
}

/***************************/
/**** Offers Chart Home ****/
/***************************/

.offerschart-grid {
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: 1fr 1fr;
}

.offerschart-text {
  margin-top: 1rem;
}

.offerschart-picture-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.offerschart-text-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-picture-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

.offerschart-text-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-picture-3 {
  grid-column-start: 3;
  grid-column-end: 4;
}

.offerschart-text-3 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-picture-4 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.offerschart-text-4 {
  grid-column-start: 4;
  grid-column-end: 5;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-picture-5 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.offerschart-text-5 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-picture-6 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
}

.offerschart-text-6 {
  grid-column-start: 4;
  grid-column-end: 5;
  align-self: center;
  margin: 0.8rem;
}

.offerschart-pictures {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 43rem) {
  .offerschart-grid {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(4, 1fr);
  }

  .offerschart-text-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    align-self: center;
  }

  .offerschart-picture-2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .offerschart-text-2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    align-self: center;
  }

  .offerschart-picture-3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .offerschart-text-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    align-self: center;
  }

  .offerschart-picture-4 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .offerschart-text-4 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    align-self: center;
  }
}

/***************************/
/******* Quote Home ********/
/***************************/

@media (min-width: 62rem) {
  .quote-content {
    width: 50%;
  }

  .quote {
    min-height: 60vh;
  }
}

@media (min-width: 130rem) {
  .quote-text {
    font-size: var(--font-size-h4);
  }

  .quote-person {
    font-size: var(--font-size-h4);
  }
}

.quote {
  position: relative;
  overflow: hidden;
  display: grid;
  margin-top: 0 !important;

  .pill {
    margin-bottom: var(--helper-50);
  }
}

.quote-image {
  display: none;

  @media (min-width: 62rem) {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: auto;
  }
}

@media (min-width: 62rem) and (max-width: 140rem) {
  .quote {
    min-height: auto;
    p,
    a {
      margin-left: 10rem;
      width: 100%;
    }
  }
}

/***************************/
/******* Big Gallery *******/
/***************************/

.full-width {
  width: 80%;
}

.image-zoom:hover {
  transition: transform 0.5s ease;
  transform: scale(1.2);
}

@media (min-width: 43rem) {
  .gallery-content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0.3rem;
  }

  .gallery-item {
    overflow: hidden;
    width: auto;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    img:hover {
      transition: transform 0.5s ease;
      transform: scale(1.2);
    }
  }

  .gallery-item:nth-child(9n + 1) {
    grid-column: span 2;
  }
  .gallery-item:nth-child(9n + 2) {
    grid-column: span 7;
  }
  .gallery-item:nth-child(9n + 3) {
    grid-column: span 3;
  }
  .gallery-item:nth-child(9n + 4) {
    grid-column: span 5;
  }
  .gallery-item:nth-child(9n + 5) {
    grid-column: span 2;
  }
  .gallery-item:nth-child(9n + 6) {
    grid-column: span 5;
  }
  .gallery-item:nth-child(9n + 7) {
    grid-column: span 4;
  }
  .gallery-item:nth-child(9n + 8) {
    grid-column: span 6;
  }
  .gallery-item:nth-child(9n + 9) {
    grid-column: span 2;
  }
}

@media (min-width: 43rem) and (max-width: 62rem) {
  .gallery-item {
    height: 140px;
  }
}

@media (min-width: 62rem) {
  .gallery-item {
    height: 300px;
  }
}

@media (max-width: 43rem) {
  img {
    width: 100%;
  }
}

.gallery-pagination {
  display: flex;
  text-align: center;
  justify-content: center;
  list-style: none;
  gap: 2rem;
  margin-top: 4rem;

  a {
    text-decoration: none;
    background-color: var(--gray);
    color: white;
    padding: 1rem;
  }

  [aria-current="page"] {
    background-color: var(--primary);
  }
}

/***************************/
/***** FORMS ********/
/***************************/

.partner-form,
.course-form,
.pvcheck-form,
.internship-form {
  margin-top: var(--helper-50);

  label {
    font-weight: 700;
    display: block;
    margin-bottom: var(--helper-15);
  }

  .label-div {
    font-weight: 700;
    display: block;
    margin-bottom: var(--helper-15);
  }

  .input-div {
    margin-bottom: var(--helper-32);
  }

  .input-group {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
  }

  .input-wrapper {
    width: 100%;
  }

  .input-wrapper.input-75 {
    width: 75%;
  }

  .input-wrapper.input-50 {
    width: 50%;
  }

  .input-wrapper.input-33 {
    width: 33%;
  }

  .input-wrapper.input-25 {
    width: 25%;
  }

  .textarea-wrapper {
    width: 100%;
  }

  .textarea {
    width: 100%;
    height: 20rem;
    resize: none;
  }

  .radio-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
  .radio-wrapper.two-columns-radio {
    width: 48%;
    @media (max-width: 43rem) {
        width: 100%;
    }
  }
  .select-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--helper-50);
  }

  .select-element {
    font-size: 0.8rem;
    font-family: sans-serif;
    padding: 0.6em 1.4em 0.5em 0.8em;
    margin: 0;
    border: 1px solid var(--gray);
    border-radius: 0.1rem;
    background-color: var(--white);
    outline: none;
  }

  .input,
  .textarea {
    width: 100%;
    margin-bottom: var(--helper-15);
    font-size: var(--font-size-p);
    line-height: 2;
    padding: 5px;

    &:invalid:focus {
      outline-color: red !important;
    }
  }

  .radio-wrapper-input {
    display: flex;
    flex-direction: row;
    margin-right: 2rem;
  }

  .radio {
    justify-items: flex-start;
    margin-left: 0.3rem;
    margin-bottom: 1rem;
  }

  input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border: 2px solid var(--highlight-gray);
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;

    &:invalid:focus {
      border-color: red;
    }
  }

  input[type="radio"]:checked {
    background: var(--highlight-gray);
  }

  .input-wrapper,
  .textarea-wrapper,
  .radio-wrapper {
    margin-bottom: var(--helper-25);
  }

  .checkbox-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--helper-50);

    input[type="checkbox"] {
      margin: 0 1rem;

      &:invalid:focus {
        outline: 1px solid red;
      }
    }

    input[type="checkbox"].privacy-checkbox {
      margin: 0;
      margin-right: 1rem;

      &:invalid:focus {
        outline: 1px solid red;
      }
    }
  }

  .image-checkboxes {
    img {
      margin-bottom: 1rem;
      padding: 0.5rem;
      background-color: #f1f1f1;
      box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
        rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    }

    legend {
      font-weight: 700;
    }

    div {
      text-align: center;
    }
  }

  .privacy {
    .checkbox-wrapper {
      margin-bottom: var(--helper-25);

      label {
        line-height: 1.65;
        font-weight: normal;
      }

      input[type="checkbox"] {
        margin-top: 0.5rem;

        &:invalid:focus {
          outline: 1px solid red;
        }
      }
    }
  }

  .submit {
    border: none;
  }

  .input-group-roof {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .input-item-roof {
    display: flex;
    flex-direction: row;
    width: 300%;

    span {
      margin-left: 0.3rem;
    }
  }
}

.image-brick {
  width: 50px;
}

.image-roof-wrapper {
  width: 100px;
  height: 70px;
}

@media (max-width: 43rem) {
  .partner-form,
  .pvcheck-form {
    flex-direction: column;

    .input-wrapper {
      width: 100%;
      margin-right: 0;
    }
  }
}
@media (max-width: 43rem) {
  .pvcheck-form,
  .request-form {
    .input-group-roof {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    span,
    label,
    p {
      font-size: 0.7rem;
    }

    input::placeholder,
    textarea::placeholder {
      font-size: 0.7rem;
    }
  }
}

.honeypot {
  position: absolute;
  left: -9999px;
}

fieldset {
  border: none;
  margin-inline-start: 0;
}

legend {
  margin-bottom: var(--helper-25);
}

/***************************/
/********** LIST  **********/
/***************************/

.column-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;

  @media (max-width: 43rem) {
    grid-template-columns: 1fr;
  }
}

.bigger-list ul li {
  font-size: var(--font-size-h6);
  padding-top: 1rem;
}

.checkmark-list {
  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    position: relative;
    padding-left: 1.5rem;
  }
}

.checkmark-list-white {
  li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' fill='white' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
      no-repeat;
  }
}

.checkmark-list-black {
  li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' fill='black' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
      no-repeat;
  }
}
